<template>
  <section class="preview-paper-layout">
    <!--    <Card dis-hover class="preview-paper-action">-->
    <!--      <table class="basic-info-table">-->
    <!--        <tr>-->
    <!--          <td>总分</td>-->
    <!--          <td>100</td>-->
    <!--        </tr>-->
    <!--        <tr>-->
    <!--          <td>总题量</td>-->
    <!--          <td>0</td>-->
    <!--        </tr>-->
    <!--      </table>-->
    <!--      -->
    <!--      <Button type="primary" class="action-btn" @click="goBack">-->
    <!--        <JYIcon href="#icon-huifu"/>返回列表-->
    <!--      </Button>-->
    <!--      <Button type="primary" class="action-btn">-->
    <!--        <JYIcon href="#icon-daoru5"/>下载试卷-->
    <!--      </Button>-->
    <!--      <Button type="primary" class="action-btn" @click="handleEdit">-->
    <!--        <JYIcon href="#icon-bianji"/>编辑试卷-->
    <!--      </Button>-->
    <!--    </Card>-->
    <div style="flex: 1">
      <div class="preview-paper-content">
        <h1 class="paper-title">{{ paperInfo.name }}</h1>
        <paper-view ref="paper-view" :sectionList="paperInfo.sectionVOS" />
      </div>
    </div>
  </section>
</template>

<script>
import PaperView from '@components/manage/exam/paper/PaperView'
import JYIcon from '@components/common/JYIcon'
import PaperAPI from '@api/paper'
export default {
  mounted() {
    this.fetchPaperInfo()
  },
  data() {
    return {
      paperInfo: {}
    }
  },
  methods: {
    async fetchPaperInfo() {
      const paperInstance = this.$refs['paper-view']
      paperInstance.setLoadingStatus()
      const { id } = this.$route.params
      try {
        const { res } = await PaperAPI.getAllPaperInfoById(id)
        this.paperInfo = res
      } catch (e) {
      } finally {
        paperInstance.unsetLoadingStatus()
      }
    },
    goBack() {
      this.$router.go(-1)
    },
    handleEdit() {
      const { id } = this.$route.params
      this.$router.push(`/manage/exam/paper/edit/${id}`)
    }
  },
  components: {
    PaperView,
    JYIcon
  }
}
</script>

<style lang="less" scoped>
@import '../../../../theme/mixin.less';
.preview-paper-layout {
  padding: 20px;
  height: 100%;
  overflow-y: auto;
  text-align: start;
  display: flex;
}
.preview-paper-content {
  .--mixin-shadow;
  flex: 1;
  background: white;
  margin-bottom: 30px;
}
.preview-paper-action {
  width: 300px;
  margin-right: 15px;
  align-self: flex-start;
}
.action-btn {
  width: 100%;
  margin-top: 10px;
}
.basic-info-table {
  tr {
    min-width: 50px;
    &:first-child {
      min-width: 50px;
    }
  }
}
.paper-title {
  font-weight: 900;
  text-align: center;
  margin-bottom: 20px;
}
</style>
