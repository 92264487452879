<template>
  <section>
    <div v-if="loading" class="loading-indicator">
      <JYIcon href="#icon-loading-copy" class="jy-animate-spin" />
      试卷加载中
    </div>
    <div v-else>
      <div
        v-if="sectionList && sectionList.length === 0"
        style="text-align: center;min-height: 100px; display: flex; flex-direction: column; align-items: center;color: gray; padding: 10px;"
      >
        <JYIcon style="font-size: 50px; margin-bottom: 20px;" href="#icon-chakan4" />
        该试卷下没有大题
      </div>
      <div v-for="(section, sindex) in sectionList" :key="section.name">
        <div class="section-header">{{ section.name }}</div>
        <h3 v-if="section.questionVOS && section.questionVOS.length === 0" style="color: #c3c3c3; font-size: 14px;">
          (该大题下未设置小题)
        </h3>
        <div v-for="(question, qindex) in section.questionVOS" :key="question.id" class="preview-question-item question-image-size-restrict">
          <div slot="title" class="question-header">
            <section>{{ qindex + 1 }}.</section>
            <section style="display: flex; align-items: center;">
              <span style="marginRight: 5px;">分数</span>
              <InputNumber
                v-if="editable"
                :max="100"
                :min="0"
                @on-change="handleScoreChange"
                size="small"
                style="width: 60px;"
                v-model="sectionList[sindex].questionVOS[qindex].points"
              />
              <span v-else>{{ sectionList[sindex].questionVOS[qindex].points }}</span>
              <JYSimpleButton v-if="editable" style="margin-left: 20px; font-size: 12px" @click="handleQuestionDelete(question.questionId)"
                >删除</JYSimpleButton
              >
            </section>
          </div>
          <BaseQuestion :question="question" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import JYIcon from '@components/common/JYIcon'
import BaseQuestion from '@components/common/questions/BaseQuestion'
import JYSimpleButton from '@components/common/JYSimpleButton'
export default {
  props: {
    sectionList: {
      required: true
    },
    editable: {
      default: false
    }
  },
  data() {
    return {
      dataSectionList: {},
      loading: false
    }
  },
  methods: {
    handleScoreChange() {
      this.$emit('on-score-change', [...this.sectionList])
    },
    handleQuestionDelete(questionId) {
      this.$emit('on-question-delete', questionId)
    },
    setLoadingStatus() {
      this.loading = true
    },
    unsetLoadingStatus() {
      this.loading = false
    }
  },
  components: {
    JYIcon,
    JYSimpleButton,
    BaseQuestion
  }
}
</script>

<style lang="less" scoped>
.preview-question-item {
  width: 100%;
  margin-top: 10px;
  padding: 20px;
  border-bottom: solid 1px #e3e3e3;
}
.question-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.loading-indicator {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}
.section-header {
  background: #ecf0f4;
  padding: 10px 20px;
  border-bottom: solid 1px #e3e3e3;
  font-weight: 900;
}
</style>
